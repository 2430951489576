const WITNESS_TYPE_VIGILANT_ID = "GfTXTyowoGqR5UStPm0I";
import witnessTypes from "@/data/cases/lists/witnessTypes.json";

export default (self) => {
  let fields = [];

  fields.push({
    size: "1/2",
    type: "input",
    validation: "required|alpha_spaces",
    attribute: "name",
  });
  fields.push({
    size: "1/2",
    type: "input",
    validation: "required|alpha_spaces",
    attribute: "lastname",
  });
  fields.push({ size: "1/2", type: "input", attribute: "code" });
  fields.push({
    size: "1/2",
    type: "input",
    validation: "email",
    attribute: "email",
  });
  fields.push({
    size: "1/2",
    type: "input",
    validation: "digits:9",
    attribute: "mobile",
  });
  fields.push({
    size: "1/2",
    type: "select",
    attribute: "customer",
    validation: "required",
    extra: {
      optionsFunction() {
        return self.customers
          ? self.customers.filter((c) =>
              c.casesTypes.map((c) => c.id).includes("penal")
            )
          : [];
      },
    },
    readonly: true,
  });

  fields.push({
    size: "1/2",
    type: "enum",
    validation: "required",
    attribute: "witnessType",
    extra: {
      enumType: "witnessTypes",
      options: witnessTypes,
      customSort(a, b) {
        return a.order - b.order;
      },
    },
  });
  fields.push({
    size: "1/2",
    type: "input",
    attribute: "nTip",
    visibility(model) {
      return (
        model.witnessType && model.witnessType.id === WITNESS_TYPE_VIGILANT_ID
      );
    },
  });

  return fields;
};
