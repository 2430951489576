<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    v-if="!!entity"
    :showDeleteButton="true"
  />
</template>
<script>
import { mapGetters } from "vuex";

import FormPage from "@/components/penal-retail-helpers/FormPage";
let collectionName = "witnesses";

import formConfig from "@/helpers/witnessesFormConfig";
import { db } from "../../../firebase/firebaseConfig";

import { createRelatedCasesColumns } from "@/helpers/utils";

export default {
  components: {
    FormPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allEstablishments", "allCases"]),
  },
  data() {
    let data = {
      entity: null,
      collectionName,
      tabs: [
        {
          name: `${collectionName}.tabs.general`,
          attributes: formConfig(this),
        },
        {
          name: `${collectionName}.tabs.cases`,
          attributes: this.defineCasesTab(),
        },
      ],
    };

    return data;
  },
  created() {
    this.$bind(
      "entity",
      db.collection(this.collectionName).doc(this.$route.params.id),
      {
        wait: true,
      }
    );
  },
  methods: {
    defineCasesTab() {
      let columns = createRelatedCasesColumns(this);

      let config = [];
      config.push({
        size: "1/1",
        type: "list",
        extra: {
          data: () => {
            return this.allCases.filter((x) =>
              (this.entity.cases || []).includes(x.id)
            );
          },
          filters: [],
          gridConfig: {
            components: {},
            gridOptions: {},
            columnDefs: columns,
            defaultColDef: {
              sortable: true,
              resizable: true,
            },
            onRowClicked: (params) => {
              this.$router
                .push("/main/cases/" + params.data.id)
                .catch(() => {});
            },
          },
        },
      });

      return config;
    },
    async prevalidate(data, collection, errors) {
      if (data.code) {
        let otherCodes = (
          await collection
            .where("code", "==", data.code)
            .where("customer.id", "==", this.entity.customer.id)
            .get()
        ).docs
          .filter((x) => x.exists)
          .filter((x) => x.id !== this.$route.params.id);
        if (otherCodes.length > 0) {
          errors.add({
            field: "code",
            msg: this.$t(`${collectionName}.validations.code.unique`),
          });
          return true;
        }
      }

      return false;
    },
  },
};
</script>
